import {
  IPlaceholderRequest,
  IProductInfo,
  getOfferName,
  getPrice,
  getPriceDescription,
  getResponsivePrice,
  useCmsConfig,
  useSwitchCmsData,
  getUnaTantumPriceDescription,
  IConfigCMSParams,
  cmsDataID,
  getCharacteristicValues,
} from '@vfit/business-data-access';
import { getFormattedPriceString } from '@vfit/business-data-access';
import { IHeroBanner } from '../TopProduct/topProduct.model';
import { IProduct } from './widgetProductHeroBanner.models';

/**
 * Add in documentation only for product hero banner widget
 * @param elements
 */
const getHeroBanner = (product: IProduct, dxlProduct?: IProductInfo): IHeroBanner => {
  const { cmsConfig, api } = useSwitchCmsData(cmsDataID.PLACEHOLDER) as IConfigCMSParams;
  const { placeholders } = useCmsConfig(cmsConfig, api) as IPlaceholderRequest;

  if (product?.isEditorialCard === 'false' && !dxlProduct)
    return {
      image: product.imageBackgroundUrl || '',
    };
  return {
    offerId: product.dxlId,
    isEditorialCard: product.isEditorialCard,
    taggingProductPrice: product.taggingProductPrice || getFormattedPriceString(dxlProduct) || '',
    title:
      product.overrideOfferName ||
      (dxlProduct && getOfferName(dxlProduct, placeholders?.placeholders)),
    productType: product.productType,
    priceDescription:
      product.overrideCostActivationHtml ||
      (dxlProduct && getPriceDescription(dxlProduct, placeholders?.placeholders)),
    unaTantumPriceDescription:
      product.overrideUnaTantumCostActivationHtml ||
      (dxlProduct && getUnaTantumPriceDescription(dxlProduct, placeholders?.placeholders)),
    price:
      product.overrideCostRenewalHtml ||
      (dxlProduct && getPrice(dxlProduct, placeholders?.placeholders)),
    shortDescription: product?.shortDescription,
    series: product.series,
    priceResponsive:
      product.overrideCostRenewalResponsive ||
      (dxlProduct && getResponsivePrice(dxlProduct, placeholders?.placeholders)),
    frontLabel: product.frontLabel || '',
    image: product.imageBackgroundUrl || '',
    backLabel: product.backLabel || undefined,
    offerLabel: product.offerLabel || '',
    actions: product.actions,
    retarget: product.retarget,
    version: product.version,
    landingprive: product?.landingprive,
    taggingProductName:
      product.taggingProductName ||
      (dxlProduct && getOfferName(dxlProduct, placeholders?.placeholders)),
    timeDiscount: dxlProduct?.timeDiscount,
    timeDiscountLabels: {
      timeDiscountTag: getCharacteristicValues(dxlProduct?.characteristic || [], 'timeDiscountTag'),
      timeDiscountDetails: getCharacteristicValues(dxlProduct?.characteristic || [], 'timeDiscountDetails'),
      timeDiscountTagForDevicePages: getCharacteristicValues(dxlProduct?.characteristic || [], 'timeDiscountTagForDevicePages'),
    }
  } as IHeroBanner;
};

export { getHeroBanner };

