import { StepperStateCards } from '@vfit/shared/components';
import { API, PAGES } from '@vfit/shared/data-access';
import { IStepperUtils } from '@vfit/shared/models';
import { useRouter } from 'next/router';
import { useEffect, useRef } from 'react';
import { CMS_CONFIG, ICoverageToolCongifg, useCmsConfig } from '@vfit/business-data-access';
import { CoverageToolModalContainer } from './coverageToolModalContent.style';
import { ICoverageToolModalProps } from './coverageToolModal.models';
import { useCoverageToolModalProvider } from './coverageToolModal.context';

const CoverageToolModalContent = ({ handleClose, backgroundImage }: ICoverageToolModalProps) => {
  const stepperRef = useRef<IStepperUtils>();
  const { steps, slidesGoBack, setSlidesGoBack, offerType } = useCoverageToolModalProvider();
  const nodeRef = useRef(null);
  const { push, pathname, asPath } = useRouter();
  const { coveragetool } = useCmsConfig(
    CMS_CONFIG[PAGES.BUSINESS_FIXED_LINE],
    API.CMS_BFL_GET_COVERAGE_TOOLS
  ) as ICoverageToolCongifg;

  const handleRedirectFwaC2C = () => {
    const paths = pathname.split('/', -1);
    const pathsFwa = asPath.split('/', -1);
    const isShoppingCart = paths[paths.length - 1] === PAGES.SHOPPING_CART;
    const isShoppingCartFwa =
      `${pathsFwa[pathsFwa.length - 2]}/${pathsFwa[pathsFwa.length - 1]}` ===
      PAGES.SHOPPING_CART_FWA;

    if (!isShoppingCart && !isShoppingCartFwa)
      push(offerType?.['isFwa'] ? PAGES.BUSINESS_SHOPPING_CART_FWA : PAGES.BUSINESS_SHOPPING_CART);
    else if (isShoppingCart && offerType?.['isFwa']) push(PAGES.BUSINESS_SHOPPING_CART_FWA);
    else if (isShoppingCartFwa && !offerType?.['isFwa']) push(PAGES.BUSINESS_SHOPPING_CART);
    else handleClose?.();
  };

  const handleRedirect = () => {
    const paths = pathname.split('/', -1);
    const isShoppingCart = paths[paths.length - 1] === PAGES.SHOPPING_CART;

    if (
      offerType?.['isOfferAvailable'] === false &&
      offerType?.['technology'] !== 'FWA_Outdoor_5G'
    ) {
      // push('/hub-fissa'); // MOCK
      push(coveragetool?.hubPostCoverageNoFwaPath);
    } else if (!isShoppingCart) {
      push(PAGES.BUSINESS_SHOPPING_CART);
    } else {
      handleClose && handleClose();
    }
  };

  const programmaticallyGoBackNext = async (numberSlides: number, type: 'NEXT' | 'BACK') => {
    const delay = (time: number) => new Promise((resolve) => setTimeout(resolve, time));
    if (numberSlides !== -1) {
      // eslint-disable-next-line no-restricted-syntax, @typescript-eslint/no-unused-vars
      for (const indexSlides of Array.from(Array(numberSlides))) {
        if (type === 'NEXT') {
          stepperRef?.current?._stepperGoNext();
          await delay(100);
        } else {
          stepperRef?.current?._stepperGoPrev();
          await delay(600);
        }
      }
      setSlidesGoBack(-1);
    }
  };

  useEffect(() => {
    if (slidesGoBack && slidesGoBack !== -1 && stepperRef?.current) {
      programmaticallyGoBackNext(slidesGoBack, 'BACK');
    }
  }, [slidesGoBack]);

  return (
    <CoverageToolModalContainer ref={nodeRef}>
      <StepperStateCards
        ref={stepperRef}
        steps={steps}
        onBack={handleClose}
        onClose={handleClose}
        onFinish={coveragetool?.isFwaC2C === 'true' ? handleRedirectFwaC2C : handleRedirect}
        blurredImage={backgroundImage}
      />
    </CoverageToolModalContainer>
  );
};

export default CoverageToolModalContent;
